@use "../../mixin" as m;

.fill {
  position: relative;
  overflow: hidden;
  background-color: #000;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  .unsubscribe-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding-top: 240px;
    .t {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      margin: 8px 0;
    }
    .st {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }
    @include m.mobile {
      padding-top: 120px;
      .t {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        margin: 8px 0;
      }
      .st {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
