@use "../../../mixin" as m;

.fill-first {
  .first-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 800px;

    @include m.mobile {
      min-height: 500px;
    }
    &::before {
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      content: "";
    }
  }

  .gif {
    width: inherit;

    @include m.mobile {
      scale: 2;
    }
  }
  .gif-test {
    width: inherit;
  }
  .text-title {
    position: absolute;
    top: 60%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    .title {
      width: 950px;
      color: #ffffff;
      letter-spacing: 1px;
      font-weight: bold;
      font-size: 68px;
      line-height: 94px;
      .block {
        display: block;
      }

      @include m.mobile {
        width: 380px;
        font-size: 30px;
        line-height: 44px;
      }
    }
    .detail-text {
      margin-top: 40px;
      color: #fff;
      font-size: 16px;
      opacity: 0.6;

      @include m.mobile {
        font-size: 14px;
      }
    }
    .trade-btn-wrapper {
      overflow: hidden;
      margin: 60px auto;
      width: fit-content;
      border-radius: 60px;
      background: linear-gradient(90deg, #4adfe7 0%, #4094e0 100%);
    }
    .trade-btn {
      width: 195px;
      height: 60px;
      border: none;
      border-radius: 60px;
      background: linear-gradient(90deg, #4adfe7 0%, #4094e0 100%);
      color: #000;
      font-weight: 600;
      font-size: 16px;
      font-family: Montserrat-SemiBold, Montserrat;

      @include m.mobile {
        width: 120px;
        height: 30px;
        font-size: 12px;
      }
      &:hover {
        background: #fff;
        color: #000;
      }
    }
  }
}
