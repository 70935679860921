@use "../../../mixin" as m;

.video-card {
  margin-top: 400px;
  height: 523px;
  background-color: #fff;

  @include m.mobile {
    margin-top: 120px;
  }
  .video-cover {
    margin-top: -200px;
    width: 100%;
    height: 100%;
    border-radius: 32px;
  }
  .video-container {
    position: relative;
    margin: 0 auto;
    width: 1200px;
    height: 524px;
    border-radius: 32px;
    cursor: pointer;

    @include m.mobile {
      width: 95%;
      height: 170px;
    }
  }
  .play {
    position: absolute;
    top: 15%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);

    @include m.mobile {
      width: 80px;
      height: 80px;
    }
  }
}
