@use "../../../mixin" as m;

.subscribe {
  position: absolute;
  bottom: -72px;
  right: 0;
  box-sizing: border-box;
  padding: 11px 25px;
  border: 1px solid #fff;
  border-radius: 25px;
  height: 44px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  img {
    height: 22px;
    width: auto;
    margin-right: 8px;
  }
  @include m.mobile {
    bottom: 50px;
    right: 0;
    padding: 8px 12px;
    border-radius: 25px;
    height: 22px;
    font-size: 12px;
    img {
      height: 16px;
      margin-right: 4px;
    }
  }
}

.subscribe-modal-wrap {
  .subscribe-modal {
    .ant-modal-content {
      border: 2px solid #4b9ffa;
      background-color: #0b0e11;
      .ant-modal-header {
        background-color: #0b0e11;
      }
    }
    .ant-modal-close {
      border-radius: 20px;
      border: 1px solid #4b9ffa;
      color: #fff;
    }
    .from-box {
      color: #fff;
      .showimg {
        padding-top: 20px;
        text-align: center;
        img {
          width: 120px;
          height: auto;
        }
      }
      .t {
        // margin-top: 6px;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
      }
      .st {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        margin: 8px 0 20px;
      }
      .email-input {
        height: 48px;
        border-radius: 30px;
      }
      .checkbox-box {
        margin: 16px 0 24px;
        white-space: nowrap;
        display: flex;
        // align-items: center;
        & > div:nth-of-type(1) {
          white-space: nowrap;
          line-height: 18px;
        }
        .ant-checkbox-group {
          margin-left: 9px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 5px;
          .ant-checkbox-wrapper {
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            .ant-checkbox {
              .ant-checkbox-inner {
                width: 20px;
                height: 20px;
                border-radius: 10px;
              }
            }
          }
        }
      }
      .agree-box {
        margin-bottom: 12px;
        .ant-checkbox-wrapper {
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          display: flex;
          align-items: flex-start;
          height: 36px;
          .ant-checkbox {
            height: 100%;
            padding-top: 4px;
            .ant-checkbox-inner {
              width: 14px;
              height: 14px;
              position: relative;
            }
          }
        }
      }
      .subscribe-btn {
        width: 100%;
        background-color: #4b9ffa;
        height: 48px;
        border-radius: 30px;
      }
    }
    @include m.mobile {
      max-width: 320px;
      .ant-modal-content {
        border: 2px solid #4b9ffa;
      }
      .ant-modal-close {
        border-radius: 20px;
        border: 1px solid #4b9ffa;
      }
      .from-box {
        .showimg {
          padding-top: 10px;
          img {
            width: 60px;
            height: auto;
          }
        }
        .t {
          // margin-top: 6px;
          font-size: 16px;
        }
        .st {
          font-size: 14px;
          margin: 4px 0 10px;
        }
        .email-input {
          height: 35px;
          border-radius: 30px;
        }
        .checkbox-box {
          margin: 8px 0 12px;
          flex-direction: column;
          & > div:nth-of-type(1) {
            line-height: 18px;
            margin-bottom: 4px;
          }
          .ant-checkbox-group {
            margin-left: 0px;
            .ant-checkbox-wrapper {
              font-size: 12px;
              font-weight: 500;
              .ant-checkbox {
                .ant-checkbox-inner {
                  width: 14px;
                  height: 14px;
                  border-radius: 10px;
                }
              }
            }
          }
        }
        .agree-box {
          margin-bottom: 12px;
          .ant-checkbox-wrapper {
            color: #fff;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            display: flex;
            align-items: flex-start;
            height: 36px;
            .ant-checkbox {
              height: 100%;
              padding-top: 3px;
              .ant-checkbox-inner {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
        .subscribe-btn {
          width: 100%;
          height: 35px;
          border-radius: 30px;
        }
      }
    }
  }
}
