@use "../../mixin" as m;

.lightbox {
  width: 100%;
  height: 100%;
  &-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;
    transition: opacity 300ms ease 0s;
    &.hide {
      display: none;
    }
  }
  &-content {
    position: relative;
    margin: 0 auto;
    padding-top: 12vh;
    max-height: 60%;
    width: 900px;
    height: 550px;

    @include m.mobile {
      width: 90%;
      height: 40%;
    }
  }
  &-close {
    position: absolute;
    top: 48px;
    right: 48px;
    font-size: 24px;
    cursor: pointer;
  }
  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
  }

  .playlist-container {
    position: absolute;
    left: 50%;
    overflow-x: auto;
    margin-top: 30px;
    padding: 12px 20px 8px;
    width: 1000px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.25);
    transform: translate(-50%);

    @include m.mobile {
      width: 90%;
    }
  }

  .playlist {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    gap: 30px;

    .img-wrapper {
      box-sizing: border-box;
      cursor: pointer;

      &.active {
        border: 4px solid #4094e0;
        cursor: default;
      }
    }
    img {
      width: 240px;

      @include m.mobile {
        width: 120px;
      }
    }
  }
}
