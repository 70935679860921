@use "../../../mixin" as m;

.team-card {
  display: flex;
  justify-content: center;
  padding-top: 160px;
  height: 450px;
  background-color: #fff;

  @include m.mobile {
    min-height: 280px;
  }
  .team-container {
    max-width: 590px;

    @include m.mobile {
      max-width: 80%;
    }
  }
  .title {
    color: #121212;
    font-weight: 600;
    font-size: 22px;
    opacity: 0.7;

    @include m.mobile {
      font-size: 22px;
    }
  }
  .desc {
    margin-bottom: 20px;
    color: #121212;
    font-weight: 700;
    font-size: 40px;

    @include m.mobile {
      margin-bottom: 20px;
      font-size: 26px;
    }
  }
  .sub-desc {
    color: #121212;
    font-weight: 400;
    font-size: 16px;
    opacity: 0.6;

    @include m.mobile {
      font-size: 16px;
    }
  }
}
