@use "../../../mixin" as m;

.card-title-holders {
  max-width: 750px;

  @include m.mobile {
    max-width: 95%;
  }
}

.holders-content {
  display: flex;
  padding-top: 100px;
  list-style: none;

  gap: 24px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  @include m.mobile {
    flex-wrap: wrap;
    margin-bottom: 50px;

    row-gap: 40px;
  }
  li {
    position: relative;
    padding: 24px;
    width: 282px;
    height: 328px;
    background-image: url("../../../assets/advatange_bg.png");
    background-position: top left;
    background-size: contain;
    background-repeat: no-repeat;

    @include m.mobile {
      padding-bottom: 20px;
      width: 45%;
      height: 100%;
      background-size: cover;
    }
    &:nth-child(2n) {
      margin-top: 128px;

      @include m.mobile {
        margin-top: 78px;
      }
    }
    .arrow-img {
      position: absolute;
      top: 24px;
      right: 24px;
      width: 48px;
      height: 48px;
    }
    .holder-img {
      margin-top: 116px;
      margin-bottom: 32px;
      width: 40px;
      height: 40px;

      @include m.mobile {
        width: 24px;
        height: 24px;
      }
    }
  }
  .holder-title {
    padding-bottom: 12px;
    font-size: 22px;
    line-height: 23px;

    @include m.mobile {
      font-size: 18px;
      line-height: 1.8;
    }
  }
  .holder-txt {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    opacity: 0.7;

    @include m.mobile {
      font-size: 14px;
      line-height: 1.4;
    }
  }
}
