@use "../../../mixin" as m;

.service-card {
  padding-bottom: 120px;

  @include m.mobile {
    margin-top: 100px;
  }

  .operate-content {
    color: #fff;
    .operate-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      padding-right: 100px;
      padding-left: 100px;
      height: 402px;
      background-image: url("../../../assets/operate_bg.png");
      background-position: top left;
      background-size: contain;
      background-repeat: no-repeat;

      @include m.mobile {
        flex-direction: column-reverse;
        padding-right: 0px;
        padding-left: 0px;
        height: 90%;
        text-align: center;
      }
      &:nth-child(2n) {
        flex-direction: row-reverse;

        @include m.mobile {
          flex-direction: column-reverse;
        }
      }
      .divider {
        background-color: #4adfe7;
      }
      img {
        width: 280px;
        height: 208px;

        @include m.mobile {
          margin-top: 30px;
          margin-bottom: 30px;
          width: 60%;
          height: 60%;
        }
      }
      .title {
        margin-bottom: 8px;
        color: #ffffff;
        letter-spacing: 2px;
        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
        opacity: 0.9;

        @include m.mobile {
          font-size: 20px;
          line-height: 1.6;
        }
      }
      .sub-title {
        max-width: 433px;
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        opacity: 0.9;

        @include m.mobile {
          max-width: 100%;
          font-size: 16px;
          line-height: 1.6;
        }
      }
      .desc {
        max-width: 450px;
        word-spacing: 2px;
        font-size: 14px;
        opacity: 0.8;

        @include m.mobile {
          margin-bottom: 50px;
          max-width: 100%;
          font-size: 14px;
          line-height: 1.6;
        }
      }
    }
  }
}
