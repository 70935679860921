@use "../../../mixin" as m;

.footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  // padding: 36px 0px;
  max-width: 1300px;
  width: 100%;
  // height: 100px;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  color: #fff;

  .rights {
    margin-bottom: 24px;
    text-align: right;
    font-size: 12px;

    @include m.mobile {
      font-size: 12px;
    }
  }
  .contract {
    display: flex;
    align-items: start;
    flex-direction: column;

    row-gap: 12px;
    &_item {
      cursor: pointer;
    }
  }
  .link {
    position: fixed;
    right: 24px;
    bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px 20px;
    border-radius: 60px;
    background-color: #000;
    column-gap: 20px;
    .link_item {
      display: flex;
      width: 40px;
      height: 40px;
      border-radius: 12px;
      background-color: rgba(237, 241, 253, 0.1);
      @include m.mobile {
        width: auto;
        height: auto;
        background-color: rgba(237, 241, 253, 0);
      }
    }

    @include m.mobile {
      right: 40px;
      bottom: 24px;
      left: 40px;
      justify-content: center;

      gap: 25px;
    }
    &_item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      cursor: pointer;

      @include m.mobile {
        width: 28px;
        height: 28px;
      }

      svg {
        width: 22px;
        height: 22px;
        opacity: 0.8;

        @include m.mobile {
          width: 22px;
          height: 22px;
        }
      }
    }
    &_telegram {
      svg {
        width: 26px;
        height: 26px;

        @include m.mobile {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
