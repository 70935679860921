@use "../../../mixin" as m;

.partners {
  display: grid;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;

  grid-template-columns: repeat(4, 22%);
  grid-row-gap: 60px;
  grid-column-gap: 60px;
  @include m.mobile {
    margin-bottom: 80px;
    max-width: 100%;

    grid-template-columns: repeat(2, 40%);
    grid-row-gap: 30px;
    grid-column-gap: 60px;
  }

  img {
    height: 40px;

    @include m.mobile {
      height: 30px;
    }
  }
  img:first-child {
    height: 60px;

    @include m.mobile {
      height: 40px;
    }
  }
  img:nth-child(2) {
    height: 80px;

    @include m.mobile {
      height: 70px;
    }
  }
  img.icon_Burrito {
    height: 60px;

    @include m.mobile {
      height: 40px;
    }
  }
  img.icon_axelar {
    height: 30px;

    @include m.mobile {
      height: 25px;
    }
  }
  img.icon_zetacube {
    height: 45px;

    @include m.mobile {
      height: 40px;
    }
  }

  img.icon_MultipleNetwork {
    height: 25px;

    @include m.mobile {
      height: 15px;
    }
  }
  img.icon_CoinEazy {
    height: 25px;

    @include m.mobile {
      height: 20px;
    }
  }
  // img.icon_dcent {
  //   height: 45px;

  //   @include m.mobile {
  //     height: 40px;
  //   }
  // }
  img.icon_gate {
    height: 30px;

    @include m.mobile {
      height: 20px;
    }
  }
}


.partners1 {
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  justify-content: center;
  grid-row-gap: 60px;
  grid-column-gap: 60px;
  gap: 100px;
  // grid-template-columns: repeat(3, 22%);
  @include m.mobile {
    margin-bottom: 80px;
    max-width: 100%;
    display: grid;

    grid-template-columns: repeat(2, 40%);
    grid-row-gap: 30px;
    grid-column-gap: 60px;
  }
  &>div:first-child {
    @include m.mobile {
      display: flex;
      justify-content: center;
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  img {
    height: 40px;

    @include m.mobile {
      height: 30px;
    }
  }
  img.icon_tokenpost {
    height: 30px;

    @include m.mobile {
      height: 18px;
    }
  }
}
