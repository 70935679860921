@use "../../../mixin" as m;

.blog-card {
  margin-top: 120px;
  padding-bottom: 120px;
  @include m.mobile {
    margin-top: 100px;
  }
  .ant-carousel {
    color: unset;
    .slick-dots-bottom {
      bottom: -50px;
      @include m.mobile {
        bottom: -50px;
      }
    }
    ul li {
      padding-left: 12px !important;
      &::before {
        display: none;
      }
    }
    .slick-dots li.slick-active button {
      background: linear-gradient(
        to right,
        rgba(74, 223, 231, 1),
        rgba(64, 148, 224, 1)
      );
    }
  }
  .space-between {
    display: flex;
    justify-content: space-between;
  }
  .stories-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    @include m.mobile {
      flex-direction: column;
    }
  }
  ul {
    font-size: 22px;
    font-weight: 600;
    opacity: 0.7;
    @include m.mobile {
      font-size: 22px;
    }
  }
  .title {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 30px;
    @include m.mobile {
      font-size: 28px;
    }
  }
  .blog-container {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    grid-row-gap: 60px;
    grid-column-gap: 60px;
    @include m.mobile {
      grid-template-columns: repeat(1, 100%);
    }
    :hover {
      .cover {
        scale: 1.2;
      }
    }
  }
  .container {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 32px;
    font-size: 12px;
    background: linear-gradient(180deg, transparent 0%, #0a0b0d 20%);
    max-width: 385px;
    @include m.mobile {
      max-width: 100%;
      font-size: 12px;
    }
    .title {
      font-size: 20px;
      font-weight: 400;
      opacity: 0.9;
      @include m.mobile {
        font-size: 20px;
      }
    }
    .cover-container {
      height: 160px;
      overflow: hidden;
      @include m.mobile {
        height: 180px;
      }
    }
    .cover {
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all ease-in-out 0.3s;
    }
    .content {
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 120px;
      height: calc(100% - 200px);
      @include m.mobile {
        min-height: 120px;
        height: calc(100% - 200px);
      }
      .title {
        flex: 1;
      }
    }
    .name {
      font-weight: 500;
      line-height: 14px;
      opacity: 0.8;
    }
    .date {
      font-weight: 400;
      line-height: 14px;
      opacity: 0.6;
    }
  }

  .page-btn-wrapper {
    border-radius: 100px;
    overflow: hidden;
    height: fit-content;
    @include m.mobile {
      // width: 45%;
      display: none;
    }
  }
  .page-btn {
    height: 54px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 600;
    font-size: 16px;
    border: 2px solid transparent;
    border-radius: 100px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(90deg, #000, #000),
      linear-gradient(90deg, #4adfe7, #4094e0);
    @include m.mobile {
      width: 100%;
      height: 40px;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &:hover {
      color: #4adfe7 !important;
      border-color: #4adfe7 !important;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}
