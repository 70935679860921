
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/font/Montserrat.ttf');

  font-display: auto;
}

html {
  scroll-behavior: smooth;
  
}

body {
  margin: 0;
  background-color: rgba(0, 0, 0);
  font-family: -apple-system, Montserrat-Bold, Montserrat, Barlow-Bold, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.show-color{
  color :#4094E0
}
.linear {
  background-image:-webkit-linear-gradient(left, rgba(74, 223, 231, 1) 0%, rgba(64, 148, 224, 1));
  -webkit-background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent;
}
.ml-15{
  margin-left: 15px;
}
.platform_svg{
  width:20px;
  height:17px;
}
.opacity-9 {
  opacity: 0.9;
}
.holders_svg{
  width:18px;
  height:20px;
}
.burden_svg{
      width:23px;
    height:18px;
}
.provide_svg{
   width:18px;
    height:21px
}
.widthdraw_svg{
width: 228px;
height: 183px;
}
.repay_svg{
  width:222px ;
  height: 172px;
}
.borrow_svg{
  width:236px;
   height:190px
}
.deposit_svg{
  width:196px;
   height:146px
}
svg{
  width: inherit;
  height: inherit;
}