@use "../../mixin" as m;

.fill {
  position: relative;
  overflow: hidden;
  background-color: #000;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;

  .fill-logo.logo {
    position: absolute;
    right: 0;
    top: 80px;
    // opacity: 0.7;

    @include m.mobile {
      bottom: 12%;
      top: auto;
    }
  }

  .max-w {
    margin: 0 auto;
    max-width: 1200px;
  }

  .common-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-main {
    margin: 0px auto;
  }

  .ul-card {
    ul {
      list-style: none;
      line-height: 26px;

      padding-inline-start: 0;
      li {
        position: relative;
        padding-left: 40px;
        &::before {
          position: absolute;
          top: -10px;
          left: 0;
          content: "·";
          font-size: 120px;
        }
      }
    }
  }

  .know-card {
    position: relative;
    background: #0a0b0d;
    .fill-card {
      position: relative;
      display: grid;
      margin-top: 150px;
      padding-top: 80px;
      padding-bottom: 80px;

      grid-template-columns: repeat(3, 230px);
      grid-row-gap: 60px;
      grid-column-gap: 60px;
      @include m.mobile {
        padding-bottom: 80px;

        grid-template-columns: repeat(2, 50%);
      }
      .title {
        // font-weight: 500;
        // font-size: 18px;
        // line-height: 21px;
        font-size: 28px;
        font-weight: 500;
        line-height: 1.6;
        text-align: left;

        @include m.mobile {
          font-size: 26px;
          line-height: 1.6;
        }
      }
      .cut-progress {
        width: 150px;
        height: 1px;
        margin: 16px 0 20px;
        background-color: rgba($color: #fff, $alpha: 0.1);
        position: relative;
        &::after {
          content: "";
          display: block;
          width: 20%;
          height: 2px;
          background-color: #4b9ffa;
          position: absolute;
          left: 0;
          top: 0;
        }
        @include m.mobile {
          width: 100%;
        }
      }
      .desc {
        // font-weight: 500;
        // font-size: 14px;
        // opacity: 0.7;
        cursor: pointer;
        margin-bottom: 20px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.6;
        text-align: left;

        &:hover {
          text-decoration: underline;
        }

        @include m.mobile {
          font-size: 16px;
        }
      }
    }
  }

  &-card {
    margin: 0 auto;
    padding-top: 150px;
    max-width: 1200px;

    @include m.mobile {
      max-width: 90%;
    }
    .card-icon {
      text-align: center;
    }
    .card-title {
      color: #fff;
      font-weight: bold;
      font-size: 40px;
      line-height: 47px;
      opacity: 0.9;

      @include m.mobile {
        font-size: 22px;
        line-height: 1.6;
      }
    }
    .title-detail {
      margin: auto;
      color: #fefffe;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      opacity: 0.8;

      @include m.mobile {
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }
}
