@use "../../../mixin" as m;

.fill-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 120px;
  height: 120px;
  background-color: transparent;
  transition: all ease-in-out 0.3s;

  @include m.mobile {
    padding: 0px 20px;
    height: 60px;
  }

  .fill-logo {
    .logo {
      width: 150px;

      @include m.mobile {
        width: 100px;
      }
    }
  }

  &.isScrolling {
    height: 68px;
    background-color: rgba(0, 0, 0, 0.5);

    @include m.mobile {
      height: 60px;
    }
  }

  .router-container {
    display: flex;
    font-weight: 600;
    font-size: 14px;

    gap: 8px;
    @include m.mobile {
      display: none;
    }
    .router-item {
      padding: 12px;
      color: inherit;
      text-decoration: unset;
      opacity: 0.8;
      cursor: pointer;
    }
  }

  .operate-container {
    display: flex;
    align-items: center;
    column-gap: 30px;

    @include m.mobile {
      column-gap: 20px;
    }
  }

  .linear-btn-wrapper {
    overflow: hidden;
    width: fit-content;
    border-radius: 60px;
  }
  .linear-btn {
    border: 2px solid transparent;
    background-image: linear-gradient(90deg, #000, #000),
      linear-gradient(90deg, #4adfe7, #4094e0);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    font-weight: 600;
    &:hover {
      border: none;
      background: linear-gradient(90deg, #4adfe7 0%, #4094e0 100%);
      color: #fff !important;
    }
  }

  .ant-btn-default {
    width: 129px;
    height: 40px;
    border-radius: 60px;
    background-color: transparent;
    color: #fff;
    font-size: 14px;

    @include m.mobile {
      width: 120px;
      height: 30px;
      font-size: 14px;
    }
    &::before {
      position: absolute;
      z-index: -1;
      padding: 1px;
      border-radius: 60px;
      background: linear-gradient(
        to right,
        rgba(74, 223, 231, 1),
        rgba(64, 148, 224, 1)
      );
      content: "";

      inset: 0;
      -webkit-mask: linear-gradient(transparent 0 0) content-box,
        linear-gradient(transparent 0 0);
      mask: linear-gradient(transparent 0 0) content-box,
        linear-gradient(transparent 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
}
